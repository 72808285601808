<template>
  <div v-if="shops">
    <div
      class="shop-pop-up min-vh-100 shadow-sm p-2 rounded pt-5"
      v-if="cityToggleClick"
    >
      <div class="city-card bg-white mx-auto my-auto">
        <div class="card-body">
          <div
            class="d-flex mb-2 fs-4 justify-content-between align-items-center"
          >
            <span class="fw-bold" style="color: var(--primary)">{{
              $t("message.shop_page_choose")
            }}</span>
            <button
              class="btn btn-sm rounded-circle"
              style="width: 30px; height: 30px; color: var(--text-color)"
              @click="cityToggleClick = !cityToggleClick"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <li
            v-for="(city, index) in cities"
            :key="index"
            class="nav-link mb-2 p-2 list-unstyled rounded p-1 text-start"
            style="
              color: var(--text-color);
              cursor: pointer;
              background-color: var(--light);
            "
            @click="changeCity(index)"
          >
            {{ city.name }}
          </li>
        </div>
      </div>
    </div>

    <div>
      <div class="container-xxl">
        <div class="row pt-5 mt-3">
          <!--        Category Side Bar-->
          <div class="col-12 col-md-2">
            <div class="d-flex mb-3 justify-content-between align-items-center">
              <a
                @click="cityToggleClick = !cityToggleClick"
                style="color: var(--primary); cursor: pointer"
                ><i class="fas fa-compass"></i> {{ this.$route.params.city }}</a
              >
              <a
                class="d-lg-none"
                style="color: var(--primary); cursor: pointer"
                @click="showCate = !showCate"
                ><i class="fas fa-filter"></i
                >{{ $t("message.shop_page_categories") }}</a
              >
            </div>
            <div
              style="font-size: 12px"
              class="sidebar d-lg-block"
              :class="showCate ? 'd-block' : 'd-none'"
            >
              <div class="sidebar-item-header">
                <span class="fs-6"
                  ><i class="fas fa-bars"></i>
                  {{ $t("message.shop_page_categories") }}</span
                >
              </div>
              <div class="sidebar-wrapper">
                <ul class="text-start list-unstyled">
                  <li
                    :class="
                      this.$route.params.shop_type.split('-').join(' ') ===
                      'All'
                        ? 'list_active'
                        : ''
                    "
                    :key="index"
                    class="sidebar-item"
                    @click="showProduct('All')"
                  >
                    <div
                      class="d-flex align-items-center justify-content-between"
                    >
                      <span class="ms-1">All</span>
                    </div>
                    <i class="fas fa-chevron-right"></i>
                  </li>
                  <li
                    v-for="(category, index) in shop_categories"
                    :class="
                      this.$route.params.shop_type.split('-').join(' ') ===
                      category.name
                        ? 'list_active'
                        : ''
                    "
                    :key="index"
                    class="sidebar-item"
                    @click="showProduct(category.name)"
                  >
                    <div
                      class="d-flex align-items-center justify-content-between"
                    >
                      <span class="ms-1">{{ category.name }}</span>
                    </div>
                    <i class="fas fa-chevron-right"></i>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <!--        Shop Card View-->

          <div
            class="col-12 col-md-10"
            style="height: 85vh; overflow-y: scroll; overflow-x: hidden"
            v-if="filterShops.length > 0"
          >
            <div class="row">
              <div class="col-12 mb-3">
                <div
                  class="d-flex align-items-center"
                  style="
                    background-color: var(--light);
                    border-radius: var(--border-radius);
                  "
                >
                  <i class="fas fa-search mx-2"></i>
                  <input
                    type="text"
                    v-model="search"
                    @input="searchShop"
                    :placeholder="$t('message.search')"
                    class="form-control border-0"
                  />
                </div>
              </div>
              <div
                class="col-6 col-md-3"
                v-for="(shop, index) in filterShops"
                :key="index"
              >
                <router-link
                  :to="{
                    name: 'ShopInfo',
                    params: {
                      city: this.$route.params.city,
                      shop_name: shop.name.split(' ').join('-'),
                      product_type: 'All',
                    },
                  }"
                  class="text-decoration-none text-black"
                >
                  <ShopCard :shop="shop" />
                </router-link>
              </div>
            </div>
          </div>

          <div
            class="col-12 col-md-10 mt-5 pt-5"
            style="background-color: var(--background-color)"
            v-else
          >
            <div class="row align-items-center">
              <div class="align-items-center justify-content-center my-auto">
                <Vue3Lottie
                  :animationData="nodata"
                  :height="300"
                  :width="300"
                />
                <h1 class="text-center" style="color: var(--text-color)">
                  Sorry! There is no shop currently.
                </h1>
              </div>
            </div>
          </div>
          <!--        AD View-->
          <div class="col-12 col-lg-2 d-none ad-box">
            <span
              class="fs-4 fw-bold mb-3 d-none d-lg-block"
              style="color: var(--primary)"
              >Popular Shops</span
            >
            <div class="card">
              <div class="card-body">
                <img src="../assets/adverts.gif" style="width: 100%" alt="" />
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <img src="../assets/adverts.gif" style="width: 100%" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash";

const nodata = require("../assets/lotties/nodata.json");
import db from "@/config/FirebaseInit";
import ShopCard from "@/components/ShopCard";
export default {
  components: { ShopCard },
  //props:['city','shop_type'],
  data() {
    return {
      showCate: false,
      nodata,
      shop_type: "",
      selectedCity: "",
      cityToggleClick: false,
      shop_categories: [],
      shops: [],
      search: "",
      searchShops: [],
    };
  },
  computed: {
    cities() {
      return this.$store.state.cities;
    },
    filterShops() {
      if (this.search !== "") {
        return this.searchShops.filter((shop) => {
          return shop.onlineSell === "yes";
        });
      } else {
        return this.shops;
      }
    },
  },
  async mounted() {
    await db.collection("shopTypes").onSnapshot((result) => {
      result.forEach((doc) => {
        this.shop_categories.push(doc.data());
      });
    });
    if (this.$route.params.shop_type.split("-").join(" ") !== "All") {
      await db
        .collection("Lashio")
        .where(
          "shopType",
          "array-contains",
          this.$route.params.shop_type.split("-").join(" ")
        )
        .onSnapshot((snapshot) => {
          this.shops = [];
          snapshot.forEach((doc) => {
            this.shops.push(doc.data());
          });
        });
    } else {
      await db.collection("Lashio").onSnapshot((snapshot) => {
        this.shops = [];
        snapshot.forEach((doc) => {
          if (doc.data().onlineSell !== "no") {
            this.shops.push(doc.data());
          }
        });
      });
    }
  },
  methods: {
    searchShop: debounce(function (e) {
      if (e.target.value) {
        db.collection("Lashio")
          .where("name", ">=", e.target.value)
          .where("name", "<=", e.target.value + "\uf8ff")
          .onSnapshot((snapshot) => {
            this.searchShops = [];
            snapshot.docs.filter((e) => {
              const isDuplicate = this.searchShops.includes(e.id);
              if (!isDuplicate) {
                this.searchShops.push(e.data());
                console.log(this.searchShops);
                return true;
              }
              return false;
            });
          });
      } else {
        this.searchShops = [];
      }
    }, 500),
    async changeCity(index) {
      this.shops = [];
      this.cityToggleClick = !this.cityToggleClick;
      this.selectedCity = this.cities[index].name;
      await db.collection(this.selectedCity).onSnapshot((snapshot) => {
        this.shops = [];
        snapshot.forEach((doc) => {
          if (doc.data().onlineSell !== "no") {
            this.shops.push(doc.data());
          }
        });
      });
      this.$router.replace({
        name: "ShowShopByType",
        params: { city: this.selectedCity, shop_type: "All" },
      });
    },
    /*searchShop(){
      if(this.search!==""){
        return this.shops.filter((shop)=>{
          return shop.name.includes(this.search);
        });
      }else {
        return this.shop;
      }
    },*/
    async showProduct(category) {
      this.shops = [];
      this.showCate = false;
      if (category !== "All") {
        await db
          .collection("Lashio")
          .where("shopType", "array-contains", category)
          .onSnapshot((snapshot) => {
            snapshot.forEach((doc) => {
              if (doc.data().onlineSell !== "no") {
                this.shops.push(doc.data());
              }
            });
          });
      } else {
        await db.collection(this.$route.params.city).onSnapshot((snapshot) => {
          this.shops = [];
          snapshot.forEach((doc) => {
            if (doc.data().onlineSell !== "no") {
              this.shops.push(doc.data());
            }
          });
        });
      }
      this.$router.replace({
        name: "ShowShopByType",
        params: {
          city: this.$route.params.city,
          shop_type: category.split(" ").join("-"),
        },
      });
    },
  },
};
</script>
<style scoped>
.sidebar-wrapper {
  overflow-y: auto; /* Add overflow-y */
  overflow-x: hidden; /* Add overflow-x */
  /* min-height: 100vh; */
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
}
.sidebar {
  padding: 0;
}
.sidebar-item {
  cursor: pointer;
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 5px 5px;
  align-items: center;
  border-bottom: 1px solid var(--light);
}
.sidebar-item-header {
  background-color: var(--light);
  margin-bottom: 5px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-color);
}
.shop-pop-up {
  z-index: 5000;
  background-color: rgba(149, 162, 196, 0.8);
  backdrop-filter: blur(40px);
  position: absolute;
  overflow-y: scroll;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.list_active {
  background-color: var(--secondary);
  color: white;
}

.ad-box {
  margin-top: 5px;
  position: sticky;
  z-index: 4000;
  top: 200px;
  padding-top: 20px;
  background-color: white;
}
.city-card {
  padding: 10px;
  border-radius: var(--border-radius);
  top: 100px;
  min-height: 300px;
  width: 400px;
}

@media screen and (max-width: 1030px) {
  .sidebar {
    height: 100vh;
    width: 100%;
    position: fixed;
    margin: auto;
    left: 0px;
    right: 0px;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.53);
    backdrop-filter: blur(30px);
  }
  .sidebar-item {
    color: white;
    padding: 10px;
    font-size: 12px !important;
  }
  .city-card {
    width: 230px;
    height: 200px;
  }
  .card-body img {
    height: 140px !important;
  }
}
</style>
