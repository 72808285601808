<template>
  <div class="shop-box p-2 d-flex flex-column align-items-center justify-content-center">
    <img :src="shop.profileUrl" class="img-thumbnail border-0" width="150" height="150" alt="...">
    <div class="text-center text-wrap">
      <span class="fw-bold">{{shop.name}}</span>
      <div class="text-center">
        <span class="me-1 text-center badge rounded-pill" style="background-color: var(--side-bar-bg);color: var(--text-color); font-size: 11px;" v-for="(category,index) in shop.shopType" :key="index">{{category}}</span>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name:"ShopCard",
  props:['shop']
}
</script>

<style scoped>
.img-thumbnail{
  border-radius: var(--border-radius);
}
.shop-box{
  width: inherit;
  height:300px;
  margin-bottom: 15px;
  border-radius: var(--border-radius);
  background-color: var(--light);
}
</style>