<template>
<!--  <div v-if="initialLoading" class="container-fluid" style="background-color: var(&#45;&#45;background-color);">
    <div class="row min-vh-100">
      <div class="col-12 my-auto mx-auto">
        <Vue3Lottie :animationData="loading" :height="200" :width="200" />
      </div>
    </div>
  </div>-->
  <div class="shop-pop-up min-vh-100 shadow-sm p-2 rounded mt-5 pt-5"  v-if="cityToggleClick">
    <div class="city-card mx-auto my-auto">
      <div class="card-body">
        <div class="d-flex mb-2 fs-4 justify-content-between align-items-center">
          <span class="fw-bold" style="color: var(--primary);">{{$t('message.shop_page_choose')}}</span>
          <button class="btn btn-sm rounded-circle" style="width: 30px;height: 30px; color: var(--text-color);" @click="cityToggle"><i class="fas fa-times"></i></button>
        </div>
        <li v-for="(city, index) in cities" :key="index" class="nav-link mb-2 p-2 list-unstyled rounded p-1 text-start" style="color: var(--text-color);background-color: var(--light);" @click="changeCity(index)">{{city.name}}</li>
      </div>
    </div>
  </div>

  <div class="container-xxl pt-5" style="background-color: var(--background-color); color: var(--text-color);">
    <div class="row pt-4">

      <div class="col-12">
        <div class="row">
          <div class="col-12 col-lg-2 " >
            <div class="d-flex mb-3 justify-content-between align-items-center">
              <a @click="cityToggle" style="color: var(--primary); cursor: pointer" ><i class="fas fa-compass"></i> {{city}}</a>
              <a class="d-lg-none" style="color: var(--primary); cursor: pointer" @click="cateToggle"><i class="fas fa-filter"></i>{{ $t('message.shop_page_categories') }}</a>
            </div>
            <div style="font-size: 12px;" class="sidebar d-lg-block" :class="showCate?'d-block':'d-none'" >
              <div class="sidebar-item-header">
                <span class="fs-6"><i class="fas fa-bars"></i> {{ $t('message.shop_page_categories') }}</span>
                <router-link :to="{name:'ShowShopByType',params:{city:this.city,shop_type:'All'}}">{{$t('message.shop_page_showAll')}}</router-link>
              </div>
              <div class="sidebar-wrapper">
                <ul class="text-start list-unstyled">
                  <li v-for="(category, index) in shop_categories" :key="index" class="sidebar-item" @click="showShopTypes(index)">
                    <div class="d-flex align-items-center justify-content-between">
                      <span class="ms-1">{{category.name}}</span>
                    </div>
                    <i class="fas fa-chevron-right"></i>
                  </li>
                </ul>
              </div>

            </div>
          </div>
          <div class="col-12 col-md-10 col-lg-10">
            <div class="row" style="height: 85vh;overflow-y: scroll">
              <div class="col-12" v-for="(promotionCategory, index) in promotionDisplayCategories" :key="index">
                <div class="fw-bold my-2 text-start " style="color: var(--text-color-light);" v-if="promotionCategory.data.length>0">
                  <span class="me-2" style="border-left: 3px solid var(--secondary);border-radius: var(--border-radius);"></span>
                  <span class="fs-5" v-if="promotionCategory.name==='Trending'">{{$t('message.shop_page_trending')}}</span>
                  <span class="fs-5" v-if="promotionCategory.name==='Promotion'">{{$t('message.shop_page_promotion')}}</span>
                  <span class="fs-5" v-if="promotionCategory.name==='Recommend'">{{$t('message.shop_page_recommend')}}</span>
                </div>

                <div class="" v-if="promotionCategory.data.length>0" >
                  <Splide :options="{ rewind: true,autoWidth:true, autoHeight:true}">
                    <SplideSlide v-for="shop in promotionCategory.data" :key="shop.id">
                      <router-link :to="{name:'ShopInfo',params:{city:city,shop_name:shop.name.split(' ').join('-'),product_type:'All'}}" class="btn text-decoration-none text-white btn-sm">
                        <div class="swipe-card">
                          <img :src="shop.profileUrl" class="img-swipe" alt="...">
                          <div class="swipe-text" style="bottom: 0px;">
                            <span class="fw-bold fs-5 text-truncate">{{shop.name}}</span>

                            <div class="text-center my-1">

                              <span class="me-1 text-center badge rounded-pill" style="background-color: var(--white);color: var(--text-color); font-size: 11px;" v-for="(category,index) in shop.shopType" :key="index">{{category}}</span>
                            </div>

                          </div>
                        </div>

                      </router-link>

                    </SplideSlide>
                  </Splide>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
const recommend = require('../assets/lotties/recommend.json')
const promotion = require('../assets/lotties/promotion.json');
const trending = require('../assets/lotties/trending.json');
const loading = require('../assets/lotties/newLoading.json');
import db from "../config/FirebaseInit";
import "firebase/compat/app"
import '@splidejs/splide/dist/css/splide.min.css';
export default {
  /*components: {
    Carousel,
    Slide,
    Pagination,
    Navigation
  },*/
  props:['city'],

  data(){
    return{

      trending,
      promotion,
      loading,
      recommend,
      showCate:false,

      initialLoading:false,
      shopToggleClick:false,
      cityToggleClick:false,
      selectedDisplayCategory:'',
      promotionDisplayCategories:[],

      selectedCity:this.city,
      shop_category:'Shop Types',
      promotion_shops:[],
      shop_categories:[],
    }

  },
  computed:{
    cities(){
      return this.$store.state.cities;
    },
  },
  async created() {
    this.initialLoading = true;
    await db.collection('shopTypes').onSnapshot((result)=>{
      result.forEach(doc=>{
        this.shop_categories.push(doc.data());
      });
    });
    await db.collection('displayPromotionType').get().then((result)=>{
      result.forEach(doc=>{
        //console.log(doc.data())
        if(!this.promotionDisplayCategories.some((promo)=>promo.name===doc.data().name)){
          this.promotionDisplayCategories.push({'data':[],...doc.data()});
        }
        setTimeout(()=>{
          this.initialLoading = false
        },1500);
        //this.initialLoading = false;

        // console.log("Size" , result.size);
        // console.log(this.promotionDisplayCategories.length)
        // if(result.size === this.promotionDisplayCategories){
        // }
        //console.log('filter',this.promotionDisplayCategories)
      });
      // console.log(this.promotionDisplayCategories)
      if(result.docs.length===this.promotionDisplayCategories.length){
        db.collection(this.selectedCity).where('promotionDisplayType','!=','default').onSnapshot((results)=>{
          results.forEach(doc=>{
            if(doc.data().onlineSell !== 'no'){
              this.promotionDisplayCategories.forEach(cate=>{
                if (!cate.data.some(result=>result.id===doc.id) && cate.name === doc.data().promotionDisplayType){
                  cate.data.push(doc.data());
                }

              });
            }
            }
            );

        });
      }
      // console.log(this.promotionDisplayCategories)
    });
    },
  methods:{
    cateToggle(){
      this.showCate = !this.showCate;
    },
    cityToggle(){
      this.cityToggleClick = !this.cityToggleClick;
    },
    async changeCity(index){
      this.selectedCity = this.cities[index].name;
      this.$router.replace({name:'Shops', params:{city:this.selectedCity}})
      this.cityToggleClick = !this.cityToggleClick;
      this.promotionDisplayCategories=[];
      await db.collection('displayPromotionType').get().then((result)=>{
        result.forEach(doc=>{
          if(!this.promotionDisplayCategories.some((promo)=>promo.name===doc.data().name)){
            this.promotionDisplayCategories.push({'data':[],...doc.data()});
          }
        });
        //console.log(this.promotionDisplayCategories)
        if(result.docs.length===this.promotionDisplayCategories.length){
          db.collection(this.selectedCity).where('promotionDisplayType','!=','default').onSnapshot((results)=>{
            results.forEach(doc=>{
              if(doc.data().onlineSell!=='no'){
                this.promotionDisplayCategories.forEach(cate=>{
                  if (!cate.data.some(result=>result.id===doc.id) && cate.name === doc.data().promotionDisplayType){
                    cate.data.push(doc.data());
                  }
                });

              }
            })
          });
        }
        //console.log(this.promotionDisplayCategories)
      });

    },
    shopToggle(){
      this.shopToggleClick = !this.shopToggleClick;
    },
    showShopTypes(index){
      this.shop_category = this.shop_categories[index].name;
      this.shopToggleClick = !this.shopToggleClick;
      this.$router.push({name:'ShowShopByType',params:{city:this.city,shop_type:this.shop_category.split(' ').join('-')}})

    },
    next() {
      this.$refs.slick.next();
    },

    prev() {
      this.$refs.slick.prev();
    },

  }
}
</script>

<style scoped>
.splide__slide{
  margin: 5px;
  width: 200px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  background-color: var(--light);
  backdrop-filter: blur(20);
  border-radius: var(--border-radius);

}
.splide__arrow .splide__arrows--ltr{
  background-color: var(--primary) !important;
}
.splide__slide:hover{
  transform: scale(0.99);
}
.swipe-card{
  width:100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}
.img-swipe{
  border-radius: var(--border-radius);
  background-size: cover;
  width: 180px;
  height: 180px;
}
.swipe-text{
  height: 100px;
  color: var(--text-color);
  text-align: center;
}

.img-swipe:before{
  display: block;
  position: absolute;
  color: var(--white) !important;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.17) 0, #000 100%) !important;
  content: '';
  top:0;
  right:0;
  bottom:0;
  left:0;
  opacity: 0.8;
}

.navbar{
  background-color: var(--primary);
}
.scrolling-wrapper {
  width: inherit;
  overflow-x: scroll;  overflow-y: hidden;  white-space: nowrap;
}

.shop-pop-up{
  z-index: 5000;
  background-color: rgba(149, 162, 196, 0.8);
  backdrop-filter: blur(40px);
  position: absolute;
  overflow-y: scroll;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
.city-card{
  background-color: var(--white);
  border-radius: var(--border-radius);
  top:100px;
  min-height: 300px;
  width: 350px;

}

li, .nav-link{
  margin-bottom: 3px;
  cursor: pointer;
  transition: 0.2s;
}
li:hover, .nav-link:hover{
  background-color: var(--light) !important;
  /*color: black;*/
}

.form-select{
  border: none;
  transition: 0.3s;
}
.form-select:hover{
  background-color: var(--light) !important;
}
.select-box{
  width: 230px;
}
.sidebar{
  padding:0;
  }
.sidebar-item{
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 5px 0;
  align-items: center;
  border-bottom: 1px solid var(--light);
}
.sidebar-item-header{
  background-color: var(--light);
  margin-bottom: 5px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-color);
}
@media only screen and (max-width: 820px){
  .city-card{
    width: 90%;
    height: 300px;
  }
  .sidebar{
    width:100%;
    padding-bottom: 5px !important;
    height: 100vh;
    position: fixed;
    margin: auto;
    left: 0px;
    right: 0px;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.53);
    backdrop-filter: blur(30px);

  }
  .sidebar-item{
    color: white;
    padding: 10px;
    font-size: 12px !important;
  }

}
.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}
.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 320px;
  font-weight: 700;
  display: flex;
  justify-content: start;
  align-items: center;
}
.sidebar-wrapper{
  height: 75vh; /* Add height */
  overflow-y: auto;  /* Add overflow-y */
  overflow-x: hidden;  /* Add overflow-x */
  /* min-height: 100vh; */
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
}
.left-wrapper{
  height: 100vh; /* Add height */
  overflow-y: auto;  /* Add overflow-y */
  overflow-x: hidden;  /* Add overflow-x */
  /* min-height: 100vh; */
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
}
.cate-list:hover{
  color: var(--primary);
}
</style>